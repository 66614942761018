import { IPublicClientApplication, PublicClientApplication } from "@azure/msal-browser";
import { AppConfigs } from "config/appConfigs";
import { businessGroupDetails } from "consts/globals";

type IdTokenClaims = {
  roles?: string[]
}

export class AuthClientProvider {
  private allowedTenants = ["72f988bf-86f1-41af-91ab-2d7cd011db47"];

  /** The singleton instance of this class. */
  private static instance: AuthClientProvider;


  /** MSAL Client instance. */
  private msalClient: IPublicClientApplication;


  /**
   * The constructor. Starts the authentication flow.
   */
  private constructor() {
    this.msalClient = new PublicClientApplication(AppConfigs().authentication.msalConfig);
  }

  /**
* Static method to get class instance.
*/
  public static getInstance() {

    if (!AuthClientProvider.instance) {
      AuthClientProvider.instance = new AuthClientProvider();
    }

    return AuthClientProvider.instance;
  }

  public getPublicClientInstance = (): IPublicClientApplication => this.msalClient;

  public getAuthenticationToken = async (
    scope: string
  ): Promise<string> => {

    const account = this.msalClient.getAllAccounts()[0];
    // to avoid warning in unit tests
    if (!account) {
      return "";
    }
    // Generate token using Template Service scope by default 
    const silentTokenRequest = { scopes: [scope], account };

    const authenticationResponse =
      await AuthClientProvider.getInstance().msalClient.acquireTokenSilent(silentTokenRequest);
    return authenticationResponse.accessToken;
  };



  public getCurrentUser = (): string => {

    const allAccounts = this.msalClient.getAllAccounts();
    const currentAccountUserName = allAccounts.length > 0 ? allAccounts[0].username : '';
    // to avoid warning in unit tests
    return currentAccountUserName;
  };

  // Used to get replace the CCF API query
  public getCurrentUserClaimRoles(): string[] {
    const allAccounts = this.msalClient.getAllAccounts();
    const currentAccount = allAccounts.length > 0 ? allAccounts[0] : undefined;
    if (
      currentAccount
    ) {
      const { tenantId } = currentAccount;

      if (!this.isTenantAllowed(tenantId)) {
        this.handleUnauthorizedTenant();
      }
    }

    let businessUnits: string[] = [];
    if (currentAccount?.idTokenClaims as IdTokenClaims) {
      const { roles } = (currentAccount?.idTokenClaims as IdTokenClaims);
      businessUnits = businessGroupDetails.filter((b) => roles?.includes(b.businessGroup)).map(m => m.tenant);
    }
    return businessUnits;
  }

  public getCurrentUserAssignedRoles = (): string[] => {
    const allAccounts = this.msalClient.getAllAccounts();
    const currentAccount = allAccounts.length > 0 ? this.msalClient.getAllAccounts()[0] : undefined;
    let assignedRoles: string[] = [];
    if (currentAccount?.idTokenClaims) {
      assignedRoles = (currentAccount.idTokenClaims as IdTokenClaims).roles || [];
    }
    return assignedRoles;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  public isTenantAllowed(tenantId: string) {
    return this.allowedTenants.includes(tenantId);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private async handleUnauthorizedTenant() {
    await this.msalClient.logout();
  }

}